import React from 'react';
import searchTrendsLogo from '../../../assets/images/searchtrendsLogo.png';
import World from '../../icons/World';
import Login from '../../icons/Login';
import Button from '../../Button';

const Footer = () => {
  return (
    <footer className="w-full home-footer flex flex-col justify-between items-center">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <img
            src={searchTrendsLogo}
            className="mr-12 home-footer-logo"
            alt="Logo da SearchTrends"
          />
          <a href="/" className="text-white home-header-anchor">
            Descubra tendências
          </a>
          <a href="/" className="text-white home-header-anchor">
            Pesquisas recentes
          </a>
          <a href="/" className="text-white home-header-anchor">
            Sobre
          </a>
        </div>
        <div className="flex items-center">
          <button className="flex items-center home-header-iconButton">
            <World /> Português
          </button>
          <button className="flex items-center home-header-iconButton">
            <Login /> Login
          </button>
          <Button className="home-header-button" text="Quero ser PRO" />
        </div>
      </div>
      <div className="home-footer-rights">
        © {new Date().getFullYear()} SearchTrends. Todos os direitos
        reservados.
        <a href="/" className="underline ml-1">
          Política de Privacidade
        </a>
      </div>
    </footer>
  );
};

export default Footer;
