import React from "react";

function Woman() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.3" height="23.1">
      <path
        fill="#2d3748"
        d="M7.6 23a1 1 0 01-.8-.3 1.2 1.2 0 01-.3-.9v-5.7h-.7v5.7a1.2 1.2 0 01-.3 1 1.1 1.1 0 01-1.6 0 1.2 1.2 0 01-.4-1v-5.7h-2l2-8.2h-.3l-1.1 4.4a1 1 0 01-1 .8 1 1 0 01-.9-.4 1.3 1.3 0 01-.1-1.2l1.4-5a2.6 2.6 0 012.3-1.8h4.7a2.7 2.7 0 012.3 1.8l1.4 5a1.3 1.3 0 01-.1 1.2 1 1 0 01-.9.4 1 1 0 01-1-.8L9.1 7.8h-.4l2.2 8.2H8.7v5.7a1.2 1.2 0 01-.3 1 1 1 0 01-.8.3zM6.2 4.3A2.1 2.1 0 014 2.2a2.1 2.1 0 114.3 0 2.1 2.1 0 01-2.1 2.1z"
        opacity="0.2"
      ></path>
    </svg>
  );
}

export default Woman;
