import React from 'react';
import './index.css';

/*
  text: String - Texto do botão
  className: String - Classe customizada para o botão
*/
const Button = ({ text, className, onClick }) => (
  <button onClick={onClick} className={`flex items-center justify-center text-white text-base button-container ${className}`}>
    {text}
  </button>
);

export default Button;
