import React, { Fragment } from "react";
import Empty from './empty-state.png';


const EmptyState = ({
  className,
  text,
}) => {
  const classes = `justify-center text-center flex mt-6 ${ className }`
  const classes2 = `justify-center flex text-center text-gray-500 ${ className }`

  return (
    <Fragment>
    <div className={classes}>
    <img src={Empty} alt="Empty" />
    </div>
    <div className={classes2}>
      <span> {text} </span>
      </div>
    </Fragment>
  );
};
export default EmptyState;
