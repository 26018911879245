import React, { useState, Fragment } from 'react';
import Button from "../../Button";
import Loading from '../../elements/Loading';
import KeywordDetailsCard from '../../KeywordDetailsCard';
import { StatusType } from '../../SmallKeywordCard';

const TopTrends = ({ trends = [], loading }) => {
  const tendenciesTabs = {
    ALL: 'all',
    RISING: 'rising',
    LOW: 'low',
    NEUTRAL: 'neutral',
  };
  const [selectedTendency, setSelectedTendency] = useState(tendenciesTabs.ALL);

  return (
    <section className="flex flex-col items-center home-tendencies-container">
      {/* <div className="mb-32 relative flex flex-col items-center home-tendencies-proContainer"> */}
      <h2 className="font-bold text-center mb-8 home-section-heading">
        Descubra tendências
        </h2>
      <div className="mb-8 home-tendencies-tabs">
        <button
          onClick={() => setSelectedTendency(tendenciesTabs.ALL)}
          className={`text-sm home-tendencies-tabButton ${selectedTendency === tendenciesTabs.ALL
              ? 'home-tendencies-tabButton--active'
              : ''
            }`}
        >
          Todos
          </button>
        <button
          onClick={() => setSelectedTendency(tendenciesTabs.RISING)}
          className={`text-sm home-tendencies-tabButton ${selectedTendency === tendenciesTabs.RISING
              ? 'home-tendencies-tabButton--active'
              : ''
            }`}
        >
          Em alta
          </button>
        <button
          onClick={() => setSelectedTendency(tendenciesTabs.LOW)}
          className={`text-sm home-tendencies-tabButton ${selectedTendency === tendenciesTabs.LOW
              ? 'home-tendencies-tabButton--active'
              : ''
            }`}
        >
          Em baixa
          </button>
        <button
          onClick={() => setSelectedTendency(tendenciesTabs.NEUTRAL)}
          className={`text-sm home-tendencies-tabButton ${selectedTendency === tendenciesTabs.NEUTRAL
              ? 'home-tendencies-tabButton--active'
              : ''
            }`}
        >
          Neutro
          </button>
      </div>
      <div className="mb-12 home-tendencies-list">
        {
          loading
            ? <Loading text="Carregando..."></Loading>
            : (
              <Fragment>
                {
                  trends.map((keyword) => {
                    let statusType = StatusType.NEUTRAL;

                    if (keyword.growthPercentage > 0) {
                      statusType = StatusType.INCREASING;
                    } else if (keyword.growthPercentage < 0) {
                      statusType = StatusType.DECREASING;
                    }

                    return <KeywordDetailsCard data={keyword} type={statusType} />;
                  })
                }
             </Fragment>
            )
        }
      </div>
      <Button text="Ver mais tendências" className="home-tendencies-button" />
    </section>
  );
}

export default TopTrends;
