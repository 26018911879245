import React, { useState, useEffect } from "react";
import axios from "axios";
import "./assets/app.css";
import "./assets/style.css";
import SearchTrends from "./components/sections/SearchTrends";
import Box from "./components/elements/Box";
import Badge from "./components/elements/Badge";
import ChartistGraph from 'react-chartist';
import { FiArrowUp } from 'react-icons/fi';
import { FiArrowDown } from 'react-icons/fi';
import { IoIosMan } from 'react-icons/io';
import { IoIosWoman } from 'react-icons/io';
import { FcGoogle } from 'react-icons/fc';
import { RiQuestionLine } from "react-icons/ri";
import { GrGroup } from "react-icons/gr";
import EmptyState from "./components/elements/EmptyState";
import Loading from "./components/elements/Loading";
import { roundedLineChart, } from "./assets/charts.jsx";
import { GiClick } from "react-icons/gi";
import Tabs from "./components/elements/Tabs";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import NewHome from "./pages/NewHome";
import MainLayout from "./layouts/Main";
import ResultPage from "./pages/Result";

const API_URL = process.env.NODE_ENV === "production" ?
  "https://strends-api.herokuapp.com" :
  "http://localhost:8080";
const TOP_KEYWORDS = [
  "cadeira gamer",
  "marketing",
  "home office",
  "cs go",
  "maquiagem",
  "whey protein",
];

async function fetchKeywordData(keyword) {
  const params = { keyword };
  const response = await axios.get(`${ API_URL }/trends`, { params });

  if (response.data.success) {
    return response.data.data || {};
  }
};

function App() {
  const [trends, setTrends] = useState([]);
  const [searchedKeywordData, setSearchedKeywordData] = useState();
  const [matchedKeywordsData, setMatchedKeywordsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [matchKeywordsLoading, setMatchKeywordsLoading] = useState(false);

  const fetchSearchedKeyword = async (keyword) => {
    setLoading(true);
    setMatchedKeywordsData([]);
    const data = await fetchKeywordData(keyword);

    setSearchedKeywordData(data);
    setLoading(false);

    setMatchedKeywordsData(await fetchMatchKeywords(keyword));
  };

  useEffect(() => {
    async function fetchTopTrends() {
      const fetchTrendsPromises = TOP_KEYWORDS.map(async (keyword) => {
        const params = { keyword };
        const response = await axios.get(`${API_URL}/trends`, { params });

        if (response.data.success) {
          return response.data.data;
        }
      });


      setLoading(true);
      setTrends(await Promise.all(fetchTrendsPromises));
      setLoading(false);
    }

    fetchTopTrends();
  }, []);

  async function fetchMatchKeywords(keyword) {
    setMatchKeywordsLoading(true);
    const params = { keyword };
    const response = await axios.get(`${API_URL}/trends/related`, { params });


    if (response.data.success) {
      setMatchKeywordsLoading(false);
      return response.data.data;
    }
  }

  function formatVolumesByMonth(volumes) {
    return { labels: [], series: [volumes] };
  }
  function renderBadge(data) {
    if (data.growthPercentage > 30) {
      return <Badge value="em alta" className="bg-green-500" />
    }
    else if (data.growthPercentage < 0) {
      return <Badge value="em baixa" className="bg-red-500" />
    }
    else {
      return <Badge value="regular" className="bg-gray-500" />
    }
  }

  function renderKeywordDataBox(data) {
    return (
      <Box width="" height="">
        <div className="flex justify-between">
          <div className="font-bold text-gray-800 text-xl mb-2 w-3/5 flex items-center">
            <span className="mr-1">{data.keyword}</span>
            {renderBadge(data)}
          </div>
          <a target="_blank" rel="noopener noreferrer" href={buildGoogleSearchUrl(data.keyword)}>
              <FcGoogle className="inline" />
            </a>
        </div>
        <div className="flex justify-between">
          <div className="box-search text-center">
            <div className="text-gray-800 text-xs mb-2 text-center">buscas/mês</div>
            <div className="text-blue-500 font-bold text-xl">{data.volume}</div>
          </div>
          <div className="box-search text-center">
            <div className="text-gray-800 text-xs mb-2 text-center">mês anterior</div>
            {data.growthPercentage > 0 ? (<div className="text-green-500 font-bold text-xl">{data.growthPercentage}% <FiArrowUp className="inline" /></div>) : (data.growthPercentage === 0 ? (<div className="text-gray-700 font-bold text-xl">{data.growthPercentage}% -- </div>) : (<div className="text-red-500 font-bold text-xl">{data.growthPercentage}% <FiArrowDown className="inline" /></div>))}
          </div>
        </div>
        <div>
          {
            data.volumesByMonth && data.volumesByMonth.length ?
              <ChartistGraph
                className="ct-chart-white-colors mt-10"
                data={formatVolumesByMonth(data.volumesByMonth)}
                type="Line"
                options={roundedLineChart.options}
                listener={roundedLineChart.animation}
              /> : "loading"
          }
          <p className="text-gray-700 text-center text-sm last-month" style={{ fontSize: 14 }}>(Últimos 06 meses)</p>
        </div>
        <div className="flex justify-between mt-4">
          <div className="text-gray-800 text-sm mb-4"><GiClick className="inline mr-3" />Taxa de Clique</div>
          <div className="text-gray-800 text-xs">{data.clickRate}%</div>
        </div>
        <div className="flex justify-between">
          <div className="text-gray-800 text-sm"><IoIosMan className="inline mr-3" />Homens</div>
          <div className="text-gray-800 text-xs">{data.malePercentage}%</div>
        </div>
        <div className="flex justify-between">
          <div className="text-gray-800 text-sm"><IoIosWoman className="inline mr-3" />Mulheres</div>
          <div className="text-gray-800 text-xs">{data.femalePercentage}%</div>
        </div>
        <div className="flex justify-between">
          <div className="text-gray-800 text-sm"><RiQuestionLine className="inline mr-3" />Não identificado</div>
          <div className="text-gray-800 text-xs">{data.genderUnknownPercentage}%</div>
        </div>
        <div className="flex justify-between mt-4 mb-4">
          <div className="text-gray-800 text-sm mb-2"><GrGroup className="inline mr-3" /> Faixa Etária</div>
        </div>
        <div className="table-age">
          <div className="grid grid-cols-2 divide-x divide-gray-400">
            <div className="border-solid border-1 no-border border-gray-400 flex justify-between text-xs"> -18 anos <div className="font-bold text-gray-800 mr-2">{data.ageGroupUnder18Percentage}%</div></div>
            <div className="border-solid border-1 no-border border-gray-400 flex justify-between text-xs"><div className="ml-2">18 a 24 anos</div><div className="font-bold text-gray-800 mr-2">{data.ageGroup18To24Percentage}%</div></div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-400">
            <div className="border-solid border-1 no-border border-gray-400 flex justify-between text-xs">25 a 34 anos <div className="font-bold text-gray-800 mr-2">{data.ageGroup25To34Percentage}%</div></div>
            <div className="border-solid border-1 no-border border-gray-400 flex justify-between text-xs"><div className="ml-2">35 a 44 anos</div><div className="font-bold text-gray-800 mr-2">{data.ageGroup35To44Percentage}%</div></div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-400">
            <div className="border-solid border-1 no-border border-gray-400 flex justify-between text-xs">45 a 54 anos<div className="font-bold text-gray-800 mr-2">{data.ageGroup45To54Percentage}%</div></div>
            <div className="border-solid border-1 no-border border-gray-400 flex justify-between text-xs"><div className="ml-2">55 a 64 anos</div><div className="font-bold text-gray-800 mr-2">{data.ageGroup55To64Percentage}%</div></div>
          </div>
          <div className="grid grid-cols-2 divide-x divide-gray-400">
            <div className="border-solid border-1 no-border border-gray-400 flex justify-between text-xs">+65 anos <div className="font-bold text-gray-800 mr-2">{data.ageGroupPlus65Percentage}%</div></div>
            <div className="border-solid border-1 no-border border-gray-400 flex justify-between text-xs"> <div className="ml-2">Não identificado</div><div className="font-bold text-gray-800 mr-2">{data.ageGroupUnknownPercentage}%</div></div>
          </div>
        </div>
      </Box>
    )
  }

  function renderSearchKeywordData() {
    let content;

    if (loading) {
      content = <Loading className="mt-8"text="Carregando..."></Loading>;
    } else {
      content = searchedKeywordData.volume ? renderKeywordDataBox(searchedKeywordData) :
        <EmptyState text="Nenhum dado encontrado"></EmptyState>
    }

    return content;
  }

  function renderMatchedKeywordsData() {
    let content;

    if (matchKeywordsLoading) {
      content = <Loading className="mt-8" text="Carregando..."></Loading>;

      return content;
    }

    if (matchedKeywordsData.length) {
      const matchedKeywordsDataBoxes = matchedKeywordsData
        .filter((matchedKeywordData) => matchedKeywordData.volume)
        .map((matchedKeywordData) => {
          return renderKeywordDataBox(matchedKeywordData);
        });

      content = (
        <div className="flex flex-wrap justify-center">
          {matchedKeywordsDataBoxes.map((dataBox) => dataBox)}
        </div>
      )
    } else {
      content = <EmptyState text="Nenhum dado encontrado"></EmptyState>;
    }

    return content;
  }

  function renderTopTrends() {
    const content = (
      <div>
        {
          loading
            ? <Loading text="Carregando..."></Loading>
            : (
              <div className="flex flex-wrap justify-center">
                {trends.map((trend) => renderKeywordDataBox(trend))}
              </div>
            )
        }
      </div>
    )

    return renderSearchResult("Descubra tendências", content);
  }

  function renderSearchResult(title, content) {
    return (
      <section className="py-8 bg-white">
        <div className="container mx-auto flex flex-col items-center">
          <h2 className="font-bold text-gray-700 text-4xl text-center text-black">
            {title}
          </h2>
          <div className="results-container mt-8">
            {content}
          </div>
        </div>
      </section>
    )
  }

  function renderSearchResultContainer() {
    return (
      <section className="py-8 bg-white">
      <div className="container mx-auto flex flex-col items-center">
        <h2 className="font-bold text-gray-700 text-4xl text-center text-black">
          Resultado
        </h2>
        {/* <span className="bg-blue-500 h-1 w-20 block mt-4"></span>*/}
        <Tabs classes="mt-4 mb-4" initialTab="Sua busca">
          <div label="Sua busca">
            { searchedKeywordData && renderSearchKeywordData() }
          </div>
          <div label="Buscas relacionadas">
            { searchedKeywordData && renderMatchedKeywordsData() }
          </div>
        </Tabs>
      </div>
    </section>
    )
  }

  function buildGoogleSearchUrl(keyword) {
    return `https://www.google.com.br/search?q=${keyword}`;
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <div className="flex flex-col min-h-screen">
            <header className="py-4 px-6">
              <div className="flex justify-center">
                <div className="flex">
                  <svg id="graph" xmlns="http://www.w3.org/2000/svg" height="35" viewBox="0 0 41 41.007">
                    <path id="Path_66" data-name="Path 66" d="M35,6.005A20.5,20.5,0,0,0,5.713,34.7a.6.6,0,1,0,.866-.832A19.3,19.3,0,0,1,34.146,6.855,19.3,19.3,0,0,1,20.5,39.805,19.086,19.086,0,0,1,8.446,35.578a.6.6,0,1,0-.751.938A20.5,20.5,0,0,0,35,6.005Zm0,0" fill="#0065f2" />
                    <path id="Path_67" data-name="Path 67" d="M111.352,99.979a.6.6,0,1,0,0-1.2,12.59,12.59,0,0,0-11.07,18.594q.115.211.238.419a12.652,12.652,0,0,0,.814,1.206h0a12.591,12.591,0,1,0,19.816-15.535,12.639,12.639,0,0,0-6.994-4.371.6.6,0,0,0-.267,1.172,11.392,11.392,0,0,1-2.537,22.5,11.3,11.3,0,0,1-8.726-4.074l4.068-3.882,1.143,1.232a3.053,3.053,0,0,0,4.4.086l3.829-3.784,1.031,1.03a1.009,1.009,0,0,0,1.723-.714V107.39a1.214,1.214,0,0,0-1.213-1.213h-5.263a1.009,1.009,0,0,0-.714,1.723l1.025,1.025-2.523,2.487-1.116-1.2a3.056,3.056,0,0,0-4.349-.133l-4.34,4.142a11.4,11.4,0,0,1,11.031-14.238Zm-5.861,10.965a1.854,1.854,0,0,1,2.638.081l1.536,1.659a.6.6,0,0,0,.427.192.609.609,0,0,0,.435-.173l3.1-3.057a1.01,1.01,0,0,0,.005-1.433l-.834-.834h4.8a.011.011,0,0,1,.011.011v4.8l-.838-.838a1.012,1.012,0,0,0-1.423,0l-3.967,3.921a1.853,1.853,0,0,1-2.669-.05l-1.557-1.679a.6.6,0,0,0-.855-.026l-4.406,4.2a11.394,11.394,0,0,1-1.168-2.232Zm0,0" transform="translate(-90.852 -90.866)" fill="#0065f2" />
                  </svg>
                  <a href="/" onClick={e => setSearchedKeywordData(undefined)} className="ml-1 text-2xl text-gray-800 font-bold tracking-tight">searchtrends</a>
                </div>
              </div>
            </header>
            <section id="hero" className="flex flex-col items-center justify-center py-24 bg-gray-100">
              <h1 className="text-4xl font-bold text-white leading-tight mb-6 md:mb-2 text-center">
                Você sabe o que o seu cliente procura na internet?
              </h1>
              <span className="font-normal text-center text-white mb-6">Acompanhe os tópicos mais buscados no Google e identifique tendências de compra dos usuários.</span>
              <SearchTrends handleKeyword={fetchSearchedKeyword} />
            </section>

            {
              searchedKeywordData ? renderSearchResultContainer() : renderTopTrends()
            }

            <footer className="flex justify-center w-full mt-auto text-secondary-500 border-t border-gray-300 bg-gray-100">
              <div className="mx-auto px-6 py-12">
                <span>Copyright ©2020 sTrends. Todos os direitos reservados.</span>
              </div>
            </footer>
          </div>
        </Route>
        <Route path="/new-lp">
          <Home />
        </Route>
        <Route path="/result">
          <MainLayout>
            <ResultPage />
          </MainLayout>
        </Route>
        <Route path="/lp">
          <MainLayout>
            <NewHome />
          </MainLayout>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
