import React from "react";
import "./Home.css";
import SearchTrends from "../../components/sections/SearchTrends";
import Header from "../../components/elements/Header";

function Home() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />

      <section id="hero" className="flex flex-col items-center justify-center py-24 bg-gray-100">
        <h1 className="text-4xl font-bold text-white leading-tight mb-6 md:mb-2 text-center">
          Você sabe o que o seu cliente procura na internet?
        </h1>
        <span className="font-normal text-center text-white mb-6">Acompanhe os tópicos mais buscados no Google e identifique tendências de compra dos usuários.</span>
        <SearchTrends handleKeyword={() => console.log("ops!")} />
      </section>

      <section class="text-gray-700 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Master Cleanse Reliac Heirloom</h1>
            <p class="lg:w-2/4 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>
            <div class="flex m-auto mt-8">
              <button class="rounded-full bg-blue-400 text-white p-2 border-blue-500">Quero ser PRO</button>
            </div>
          </div>
        </div>
      </section>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
            <img class="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" />
          </div>
          <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Before they sold out readymade gluten</h1>
            <p class="mb-8 leading-relaxed">Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>
            <div class="flex justify-center">
              <button class="rounded-full bg-blue-400 text-white p-2 border-blue-500">Quero ser PRO</button>
            </div>
          </div>
        </div>
      </section>

      <footer className="flex justify-center w-full mt-auto text-secondary-500 border-t border-gray-300 bg-gray-100">
        <div className="mx-auto px-6 py-12">
          <span>Copyright ©2020 sTrends. Todos os direitos reservados.</span>
        </div>
      </footer>
    </div>
  );
}

export default Home;
