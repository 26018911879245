import React from 'react';
import SmallGoogleLogo from '../icons/SmallGoogleLogo';
import './index.css';
import { StatusType } from '../SmallKeywordCard';
import Dashes from '../icons/Dashes';
import ArrowDecreasing from '../icons/ArrowDecreasing';
import ArrowIncreasing from '../icons/ArrowIncreasing';
import Pointer from '../icons/Pointer';
import Man from '../icons/Man';
import Woman from '../icons/Woman';
import QuestionMark from '../icons/QuestionMark';
import ChartBar from '../icons/ChartBar';
import { roundedLineChart, } from "../../assets/charts.jsx";
import ChartistGraph from 'react-chartist';
/*
  type: StatusType - Tipo do card, vide SmallKeywordCard
*/
const KeywordDetailsCard = ({ data = {}, type }) => {
  const getStatusClass = () => {
    const classes = {
      [StatusType.NEUTRAL]: 'keywordDetailsCard-status--neutral',
      [StatusType.INCREASING]: 'keywordDetailsCard-status--increasing',
      [StatusType.DECREASING]: 'keywordDetailsCard-status--decreasing',
    };

    return classes[type];
  };

  const getIcon = () => {
    const icons = {
      [StatusType.NEUTRAL]: <Dashes />,
      [StatusType.INCREASING]: <ArrowIncreasing />,
      [StatusType.DECREASING]: <ArrowDecreasing />,
    };

    return icons[type];
  };

  function formatVolumesByMonth(volumes) {
    return { labels: [], series: [volumes] };
  };

  function buildGoogleSearchUrl(keyword) {
    return `https://www.google.com.br/search?q=${keyword}`;
  }

  return (
    <div className="keywordDetailsCard-container">
      <div className="bg-white keywordDetailsCard-chartContainer">
        <div className="flex items-center justify-between font-bold text-lg keywordDetailsCard-header">
          <div className="flex items-center">
            { data.keyword }
            <span className={`keywordDetailsCard-status ${getStatusClass()}`} />
          </div>
          <a target="_blank" rel="noopener noreferrer" href={buildGoogleSearchUrl(data.keyword)}>
            <SmallGoogleLogo />
          </a>
        </div>
        <div className="flex items-center justify-between keywordDetailsCard-monthData-container">
          <div className="flex items-center flex-col keywordDetailsCard-monthData">
            <div className="text-sm keywordDetailsCard-monthData-title">
              Buscas/mês
            </div>
            <div className="text-xl font-bold keywordDetailsCard-monthSearch">
              {data.volume}
            </div>
          </div>
          <div className="flex items-center flex-col keywordDetailsCard-monthData">
            <div className="text-sm keywordDetailsCard-monthData-title">
              Mês anterior
            </div>
            <div
              className={`flex items-center text-xl font-bold keywordDetailsCard-previousMonth ${getStatusClass()}`}
            >
              {data.growthPercentage}% {getIcon()}
            </div>
          </div>
        </div>
        <div className="w-full keywordDetailsCard-chart ">
          {
            data.volumesByMonth && data.volumesByMonth.length ?
              <ChartistGraph
                className="ct-chart-white-colors mt-10"
                data={formatVolumesByMonth(data.volumesByMonth)}
                type="Line"
                options={roundedLineChart.options}
                listener={roundedLineChart.animation}
              /> : "loading"
          }
        </div>
      </div>
      <div className="keywordDetailsCard-data-container">
        <div className="flex items-center justify-between text-sm keywordDetailsCard-data-line">
          <div className="flex items-center">
            <Pointer />
            Taxa de clique
          </div>
          <strong>{data.clickRate}%</strong>
        </div>
        <div className="flex items-center justify-between text-sm keywordDetailsCard-data-line">
          <div className="flex items-center">
            <Man />
            Homens
          </div>
          <strong>{data.malePercentage}%</strong>
        </div>
        <div className="flex items-center justify-between text-sm keywordDetailsCard-data-line">
          <div className="flex items-center">
            <Woman />
            Mulheres
          </div>
          <strong>{data.femalePercentage}%</strong>
        </div>
        <div className="flex items-center justify-between text-sm keywordDetailsCard-data-line">
          <div className="flex items-center">
            <QuestionMark />
            Não identificado
          </div>
          <strong>{data.genderUnknownPercentage}%</strong>
        </div>
        <div className="flex items-center justify-between text-sm keywordDetailsCard-data-age-title">
          <div className="flex items-center">
            <ChartBar />
            Faixa etária
          </div>
        </div>
        <div className="flex items-center mt-2">
          <div className="w-6/12 keywordDetailsCard-data-age-container">
            <div className="flex items-center justify-between text-sm keywordDetailsCard-data-age">
              <div>{'-18 anos'}</div>
              <strong>{data.ageGroupUnder18Percentage}%</strong>
            </div>
            <div className="flex items-center justify-between text-sm keywordDetailsCard-data-age">
              <div>18 a 24 anos</div>
              <strong>{data.ageGroup18To24Percentage}%</strong>
            </div>
            <div className="flex items-center justify-between text-sm keywordDetailsCard-data-age">
              <div>25 a 34 anos</div>
              <strong>{data.ageGroup25To34Percentage}%</strong>
            </div>
            <div className="flex items-center justify-between text-sm keywordDetailsCard-data-age">
              <div>35 a 44 anos</div>
              <strong>{data.ageGroup35To44Percentage}%</strong>
            </div>
          </div>
          <div className="w-6/12 keywordDetailsCard-data-age-container">
            <div className="flex items-center justify-between text-sm keywordDetailsCard-data-age">
              <div>45 a 54 anos</div>
              <strong>{data.ageGroup45To54Percentage}%</strong>
            </div>
            <div className="flex items-center justify-between text-sm keywordDetailsCard-data-age">
              <div>55 a 64 anos</div>
              <strong>{data.ageGroup55To64Percentage}%</strong>
            </div>
            <div className="flex items-center justify-between text-sm keywordDetailsCard-data-age">
              <div>+65 anos</div>
              <strong>{data.ageGroupPlus65Percentage}%</strong>
            </div>
            <div className="flex items-center justify-between text-sm keywordDetailsCard-data-age">
              <div className="text-xs">Não identificado</div>
              <strong>{data.ageGroupUnknownPercentage}%</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeywordDetailsCard;
