import React from "react";

function ArrowDecreasing() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.7" height="12">
      <path
        fill="#e63946"
        d="M11 5.1l.5.6a.6.6 0 010 1l-5.2 5.1a.6.6 0 01-.9 0L.2 6.6a.6.6 0 010-.9l.6-.6a.6.6 0 01.9 0l3 3.2V.6a.6.6 0 01.7-.6h.9a.6.6 0 01.6.6v7.7L10 5.1a.6.6 0 011 0z"
      ></path>
    </svg>
  );
}

export default ArrowDecreasing;
