import React from 'react';
import institutionalImage from '../../../assets/images/institutionalImage.png';
import Button from '../../Button';

const About = () => {
  return (
    <section className="flex items-center justify-center home-institutional-container">
      <img
        src={institutionalImage}
        className="home-learn-institutional-image"
        alt="Descrição da imagem"
      />
      <div>
        <div className="uppercase font-bold text-xs mb-2 home-instituional-tag">
          Institucional
        </div>
        <h2 className="text-left font-bold home-section-heading home-institutional-title">
          Texto breve sobre como funciona a ferramenta
        </h2>
        <p className="text-left text-base mt-8 mb-8 home-institutional-text">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
          erat, sed diam voluptua. At vero eos et accusam et justo duo.
        </p>
        <Button
          text="Quero me inscrever"
          className="home-institutional-button"
        />
      </div>
    </section>
  )
}

export default About;
