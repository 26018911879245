import React from 'react';
import logoMock from '../../../assets/images/logoMock.png';
import userPhotoMock from '../../../assets/images/userPhotoMock.png';
import CNNLogo from '../../icons/CNNLogo';
import EbayLogo from '../../icons/EbayLogo';
import GoogleLogo from '../../icons/GoogleLogo';
import CiscoLogo from '../../icons/CiscoLogo';
import AirBnbLogo from '../../icons/AirBnBLogo';
import LearnCard from '../../LearnCard';


const Testimonial = () => {
  return (
    <section className="flex flex-col items-center home-learn-container">
      <h2 className="mb-2 font-bold text-center home-section-heading home-subscribe-title">
        Aprenda com os melhores
      </h2>
      <div className="flex home-learn-cards">
        <LearnCard
          logo={logoMock}
          text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo."
          userName="Nome completo"
          userJob="Cargo e empresa"
          userPhoto={userPhotoMock}
        />
        <LearnCard
          logo={logoMock}
          text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takita sanctus."
          userName="Nome completo"
          userJob="Cargo e empresa"
          userPhoto={userPhotoMock}
        />
        <LearnCard
          logo={logoMock}
          text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo."
          userName="Nome completo"
          userJob="Cargo e empresa"
          userPhoto={userPhotoMock}
        />
      </div>
      <div className="flex items-center home-learn-footer">
        <EbayLogo />
        <CNNLogo />
        <GoogleLogo />
        <CiscoLogo />
        <AirBnbLogo />
      </div>
    </section>
  );
};

export default Testimonial;
