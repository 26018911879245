import React from "react";

const Box = ({
  children,
  width = 425,
  height = 350,
}) => {
  return (
    <div className="rounded overflow-hidden shadow mr-6 mb-6 mx-8 my-8" style={{width, height}}>
      <div className="px-6 py-4">
        { children }
      </div>
    </div>
  );
};
export default Box;
