import React, { Fragment } from "react";
import SmallKeywordCard, { StatusType } from "../../SmallKeywordCard";
import SearchTrends from "../SearchTrends";

const Hero = () => {
  return (
    <Fragment>
      <h1 className="text-center font-bold text-white mb-4 home-heading-title">
        Você sabe o que o seu cliente procura na internet?
      </h1>
      <p className="text-center text-base home-heading-subtitle">
        Acompanhe os tópicos mais buscados no Google e identifique tendências
        de compra dos usuários.
      </p>
      <div className="flex items-center home-heading-input-container">
        <SearchTrends />
      </div>
      <div className="absolute w-full home-heading-keywordCard-container overflow-x-hidden">
        <div className="flex mb-6 home-heading-keywordCard-firstRow">
          <SmallKeywordCard type={StatusType.NEUTRAL} />
          <SmallKeywordCard type={StatusType.DECREASING} />
          <SmallKeywordCard type={StatusType.INCREASING} />
          <SmallKeywordCard type={StatusType.INCREASING} />
        </div>
        <div className="flex mb-6 home-heading-keywordCard-secondRow">
          <SmallKeywordCard type={StatusType.NEUTRAL} />
          <SmallKeywordCard type={StatusType.INCREASING} />
          <SmallKeywordCard type={StatusType.DECREASING} />
          <SmallKeywordCard type={StatusType.NEUTRAL} />
          <SmallKeywordCard type={StatusType.INCREASING} />
        </div>
      </div>
    </Fragment>
  );
};

export default Hero;
