import React from "react";

function CNNLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="38">
      <path
        fill="#2d3748"
        d="M54.7 0A6.4 6.4 0 0050 2a7 7 0 00-2 5v10.8l-.4-.5-7.2-12.6A10.4 10.4 0 0037 1.1 7 7 0 0033.4 0H33a6.4 6.4 0 00-4.3 2.1A7.1 7.1 0 0026.8 7v18.6a1.2 1.2 0 01-1.2 1.3h-5.9c-6.3 0-9.1-4-9.1-8a8.3 8.3 0 012.1-5.6 8.3 8.3 0 016.3-2.5h6.4V6h-6.6A12.5 12.5 0 009 10a12.2 12.2 0 00-2.4 4 13.9 13.9 0 00-.8 4.7 12.8 12.8 0 003.3 8.8 13.3 13.3 0 0010 4h11a1.4 1.4 0 001.4-1.4V7.5A2.3 2.3 0 0133 5a2 2 0 01.6 0 2.3 2.3 0 011.9 1l14.1 22.7 1.5 2.3a1 1 0 00.8.6.7.7 0 00.2 0c.3-.2.6-.5.6-1.3v-23A2.3 2.3 0 0154.2 5a2 2 0 01.6 0 2.3 2.3 0 011.9 1L71 28.8l1.4 2.3a1 1 0 00.8.6.8.8 0 00.3 0c.4-.2.6-.6.6-1.3V.1h-4.7v17.7l-7.7-13A10.3 10.3 0 0058.4 1a7 7 0 00-3.7-1zM25.4.1h-5.8c-6 0-11.2 2-14.7 5.8a17.7 17.7 0 00-3.7 6A19.4 19.4 0 000 18.7a19.6 19.6 0 001.2 6.9 18.1 18.1 0 003.8 6c3.6 4 8.8 6 15 6h10.3a7.3 7.3 0 005.3-2 7.6 7.6 0 002-5.5v-9.5l1.5 2.7 6.5 10.6a8 8 0 006.8 4.1 6.2 6.2 0 004.5-1.8 7 7 0 001.9-5.1V20.6l2 3.2 6 10.1a8 8 0 006.8 4.1 6.3 6.3 0 004.5-1.8 7 7 0 001.9-5.1V0h-4.7v30.3a2.3 2.3 0 01-1.5 2.3 1.9 1.9 0 01-.6.1 2.3 2.3 0 01-1.8-1l-15.6-25v-.1a1 1 0 00-.9-.5 1 1 0 00-.2 0c-.4.2-.7.5-.7 1.3v22.9a2.4 2.4 0 01-1.4 2.4 1.8 1.8 0 01-.6 0 2.3 2.3 0 01-1.8-1l-15.7-25a1 1 0 00-.8-.6.8.8 0 00-.3 0c-.3.1-.7.4-.7 1.2v22.8a2.6 2.6 0 01-2.5 2.6h-11a14.4 14.4 0 01-10.9-4.4 14 14 0 01-3.6-9.6 15.1 15.1 0 01.9-5 13.5 13.5 0 012.6-4.6A12.7 12.7 0 0112.7 6a15.2 15.2 0 016-1.2h6.7V0z"
      ></path>
    </svg>
  );
}

export default CNNLogo;
