import React from "react";
import Loading from './loading.svg';


const LoadingState = ({
  className,
  text,
}) => {
  return (
    <div className={`flex flex-col justify-center align-items-center ${className}`}>
    <img width="120px" className={`flex justify-center m-auto`} src={Loading} alt="Loading" />
      <span className="text-center text-gray-500 mt-2">{text}</span>
    </div>
  );
};

export default LoadingState;
