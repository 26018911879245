import React from "react";

function Login() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.3" height="11.6">
      <path
        fill="rgba(255,255,255,.6)"
        d="M5 0v1.7h6.6V10H5v1.6h8.3V0zm1.6 3.3V5H0v1.6h6.6v1.7L10 5.8z"
      ></path>
    </svg>
  );
}

export default Login;
