import React from 'react';
import Button from "../../Button";

const TrialCTA = () => {
  return (
    <section className="bg-white flex flex-col items-center home-subscribe-container">
      <h2 className="mb-2 font-bold text-center home-section-heading home-subscribe-title">
        Aumente a eficiência da sua equipe com <span>SearchTrends</span>
      </h2>
      <div className="mb-10 home-subscribe-subtitle">
        Comece sue teste gratuito de <strong>7 dias</strong> hoje
      </div>
      <Button text="Quero me inscrever" className="home-subscribe-button" />
    </section>
  );
}

export default TrialCTA;
