import React, { Fragment, useState } from "react";
import Button from '../../components/Button';

const SearchTrends = ({ handleKeyword }) => {
  const [ keyword, setKeyword ] = useState("");

  function scrollWin() {
    window.scrollTo(0, 400);
  }

  return (
    <Fragment>
      <div className="flex items-center home-heading-input-container">
        <input
          className="bg-white text-base p-5 home-heading-input"
          placeholder="Insira a palavra-chave que deseja buscar"
          value={keyword}
          onChange={e => setKeyword(e.target.value.toLowerCase())}
          onKeyDown={e => { e.keyCode === 13 && handleKeyword(keyword) && scrollWin();}}
          type="text"
        />
        <Button className="home-heading-input-button" text="Pesquisar" onClick={() => { handleKeyword(keyword); scrollWin();}} />
      </div>
    </Fragment>
  );
};

export default SearchTrends;
