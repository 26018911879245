import React from 'react';

const QuoteEnd = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36.4" height="29.6">
    <path
      fill="#116aeb"
      d="M25.8 29.6l-4.9-4c4.2-2.7 8-8 8.4-12.1l-1.7.1c-3.6 0-6.3-2.7-6.3-6.7a6.9 6.9 0 017-6.9c4.3 0 8 3.5 8 10a25 25 0 01-10.5 19.6zm-21 0l-4.8-4c4.2-2.7 7.8-8 8.4-12.1l-1.7.1A6.3 6.3 0 01.3 7a7 7 0 017-6.9c4.3 0 8.1 3.5 8.1 10 0 8-4.5 15.3-10.6 19.6z"
    ></path>
  </svg>
);

export default QuoteEnd;
