import React from "react";

function GoogleLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="35">
      <path
        fill="#2d3748"
        d="M76.4 35a8.4 8.4 0 01-5.2-1.7 8.7 8.7 0 01-2.8-3.5l3.5-1.4a5 5 0 004.5 3.2c3 0 4.8-2 4.8-5.2v-1.2H81a6.2 6.2 0 01-4.7 2 8.8 8.8 0 01-8.6-8.8 8.7 8.7 0 012.6-6.2 8.6 8.6 0 016-2.5 6.3 6.3 0 014.7 2h.2v-1.5h3.7v15.7c0 3-.8 5.4-2.5 7a8.5 8.5 0 01-6 2.1zm.3-21.9a5.1 5.1 0 00-5 5.3 5 5 0 005 5.3c2.7 0 4.7-2.3 4.7-5.3s-2-5.3-4.7-5.3zm25.7 14a9 9 0 01-6.4-2.5 8.7 8.7 0 01-2.5-6.2 8.8 8.8 0 012.5-6.3 8.4 8.4 0 016-2.4 7.6 7.6 0 015.2 2 9.7 9.7 0 012.4 3.5l.4 1-12 4.9a4.5 4.5 0 004.4 2.6 5.1 5.1 0 004.4-2.4l3 2a9 9 0 01-7.4 3.9zm-.3-14a4.8 4.8 0 00-4.7 5l8-3.2A3.5 3.5 0 00102 13zM57 27a9.1 9.1 0 01-6.4-2.4 8.8 8.8 0 010-12.6 9.4 9.4 0 0112.8 0 8.8 8.8 0 010 12.6 9.1 9.1 0 01-6.4 2.5zm0-14a5 5 0 00-3.5 1.5 5.6 5.6 0 000 7.6 5 5 0 007 0 5.6 5.6 0 000-7.6A5 5 0 0057 13zM37.4 27a9.1 9.1 0 01-6.4-2.4A8.8 8.8 0 0131 12a9.4 9.4 0 0112.7 0 8.8 8.8 0 010 12.6 9.1 9.1 0 01-6.3 2.5zm0-14a5 5 0 00-3.6 1.5 5.6 5.6 0 000 7.6 5 5 0 007.1 0 5.6 5.6 0 000-7.6 5 5 0 00-3.5-1.5zM14 27.1a14.4 14.4 0 01-10-4A13 13 0 014.2 4a14.4 14.4 0 0110-4 13.4 13.4 0 019.6 3.7L21 6.4a9.9 9.9 0 00-7-2.7 10 10 0 00-7 2.9 9.9 9.9 0 000 14 10 10 0 007 2.8 9.4 9.4 0 007-2.7 7.7 7.7 0 002.1-4.6h-9v-3.7h12.7a11.7 11.7 0 01.2 2.3 12.1 12.1 0 01-3.3 8.6 12.9 12.9 0 01-9.7 3.9zm77.5-.5h-4V1h4v25.7z"
      ></path>
    </svg>
  );
}

export default GoogleLogo;
