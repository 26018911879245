import React from 'react';
import Button from "../../Button";
import facebookLogo from '../../../assets/images/facebookLogo.png';
import instagramLogo from '../../../assets/images/instagramLogo.png';
import youtubeLogo from '../../../assets/images/youtubeLogo.png';
import driveLogo from '../../../assets/images/driveLogo.png';
import googleLogo from '../../../assets/images/googleLogo.png';
import linkedinLogo from '../../../assets/images/linkedinLogo.png';

const BuyCTA = () => {
  return (
    <section className="flex flex-col items-center buy-pro-cta-container">
      <div className="mb-32 relative flex flex-col items-center buy-pro-cta-container-proContainer">
        <h3 className="text-center font-bold text-3xl mb-5 buy-pro-cta-container-proTitle">
          Lorem ipsum dolor sit amet consetetur sadipscing elitr
          </h3>
        <p className="text-base text-center mb-10 buy-pro-cta-container-proSubtitle">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
          erat, sed diam voluptua.
          </p>
        <Button
          text="Quero me tornar PRO"
          className="buy-pro-cta-container-button"
        />
        <img
          src={facebookLogo}
          className="absolute buy-pro-cta-container-facebook"
          alt="Logo do Facebook"
        />
        <img
          src={instagramLogo}
          className="absolute buy-pro-cta-container-instagram"
          alt="Logo do Instagram"
        />
        <img
          src={youtubeLogo}
          className="absolute buy-pro-cta-container-youtube"
          alt="Logo do Youtube"
        />
        <img
          src={driveLogo}
          className="absolute buy-pro-cta-container-drive"
          alt="Logo do Google Drive"
        />
        <img
          src={googleLogo}
          className="absolute buy-pro-cta-container-google"
          alt="Logo do Google"
        />
        <img
          src={linkedinLogo}
          className="absolute buy-pro-cta-container-linkedin"
          alt="Logo do Linkedin"
        />
      </div>
    </section>
  );
}

export default BuyCTA;
