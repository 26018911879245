import React, { Fragment, useEffect, useState } from 'react';
import axios from "axios";
import Testimonial from '../../components/sections/Testimonial';
import About from '../../components/sections/About';
import BuyCTA from '../../components/sections/BuyCTA';
import TopTrends from '../../components/sections/TopTrends';
import './index.css';

const TOP_KEYWORDS = [
  "cadeira gamer",
  "marketing",
  "home office",
  "cs go",
  "maquiagem",
  "whey protein",
];
const API_URL = process.env.NODE_ENV === "production" ?
  "https://strends-api.herokuapp.com" :
  "http://localhost:8080";
const NewHome = () => {
  const [trends, setTrends] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchTopTrends() {
      const fetchTrendsPromises = TOP_KEYWORDS.map(async (keyword) => {
        const params = { keyword };
        const response = await axios.get(`${API_URL}/trends`, { params });

        if (response.data.success) {
          return response.data.data;
        }
      });

      setLoading(true);
      setTrends(await Promise.all(fetchTrendsPromises));
      setLoading(false);
    }

    fetchTopTrends();
  }, []);

  return (
    <Fragment>
      <BuyCTA />
      <TopTrends trends={trends} loading={loading} />
      <About />
      <Testimonial />
    </Fragment>
  );
};

export default NewHome;
