import React, { useState } from "react";
import Tab from "../Tab";

const Tabs = ({ children, initialTab, classes = "" }) => {
  const [activeTab, setActiveTab] = useState(initialTab);

  function activeTabContent() {
    const activeTabEl = children.find((tab) => tab.props.label === activeTab);

    if (!activeTabEl) return undefined;

    return activeTabEl.props.children;
  }

  return (
    <div className={`tabs ${ classes }`}>
      <div className="tab-list flex items-center justify-center">
        {
          children.map((tab) => {
            const { label } = tab.props;

            return (
              <Tab
                key={label}
                active={label === activeTab}
                label={label}
                onClick={e => setActiveTab(label)}
              />
            )
          })
        }
      </div>
      <div className="tab-content">
        { activeTabContent() }
      </div>
    </div>
  );
};

export default Tabs;
