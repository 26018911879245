import React from "react";

const Badge = ({
  value,
  className,
}) => {
  const classes = `flex rounded-full px-1 py-1 ${ className }`
  return (
      <span className={classes}></span>
   
  );
};
export default Badge;
