import React from "react";

function SmallGoogleLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.4" height="16.7">
      <path
        fill="#4285f4"
        d="M16.4 8.5a9.8 9.8 0 00-.2-1.7H8.3v3.3H13a3.9 3.9 0 01-1.7 2.5v2H14a8.2 8.2 0 002.5-6z"
      ></path>
      <path
        fill="#34a853"
        d="M8.3 16.7a8 8 0 005.6-2l-2.7-2.1a5 5 0 01-7.5-2.7H.9v2.2a8.3 8.3 0 007.4 4.6z"
      ></path>
      <path
        fill="#fbbc04"
        d="M3.7 10a5 5 0 010-3.3V4.6H.9a8.4 8.4 0 000 7.5z"
      ></path>
      <path
        fill="#ea4335"
        d="M8.3 3.3a4.5 4.5 0 013.2 1.3L14 2.2A8 8 0 008.3 0 8.3 8.3 0 001 4.6l2.8 2.2a5 5 0 014.6-3.5z"
      ></path>
    </svg>
  );
}

export default SmallGoogleLogo;
