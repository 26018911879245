import React from "react";

function EbayLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="99" height="40">
      <path
        fill="#2d3748"
        d="M78 40l4.5-8.5L73 13.7a9.7 9.7 0 01.7 3.7v11l.2 3.2h-4.7v-3.2a7.4 7.4 0 01-2.2 2 13.3 13.3 0 01-7.5 2 11 11 0 01-6.7-1.8A6.6 6.6 0 0150 25v-.8c0 .4-.2.7-.3 1a10.9 10.9 0 01-2.4 3.8 10.7 10.7 0 01-3.7 2.5 12.9 12.9 0 01-4.8.8 12.7 12.7 0 01-7-1.9 7.5 7.5 0 01-2-1.9l-.2 3h-5l.2-3.8v-6H5A7 7 0 007.1 27a7.8 7.8 0 005.5 2 7.3 7.3 0 005.2-2 5 5 0 001.3-1.8h5.1a7.3 7.3 0 01-1.7 3.5 9 9 0 01-3.4 2.4 16 16 0 01-6.2 1.2c-4.4 0-7.7-1.1-10-3.2-2-2-3-4.8-3-8.5 0-4.2 1.3-7.3 3.8-9.4a14 14 0 019-2.6 15.2 15.2 0 015.9 1 10 10 0 013.7 2.7 10.6 10.6 0 012.5 6.8V0h5v12.5a6.7 6.7 0 012-1.8 12.5 12.5 0 017-1.9 11.5 11.5 0 018.7 3.4 12 12 0 013 8.4 14.5 14.5 0 01-.1 2.2c1.2-3.3 5.2-5 12-5h6.2v-1a4.1 4.1 0 00-1.8-3.6 7.5 7.5 0 00-4.4-1.1c-1.5 0-3.5.2-4.7 1.7a3.3 3.3 0 00-.8 1.7h-5.3a5.8 5.8 0 011.7-3.8 8.5 8.5 0 013.3-2 18.3 18.3 0 016.2-1c4 0 6.9 1 8.7 2.7a6.7 6.7 0 01.8 1L71 9.7h5.9l8.4 17.1 8.5-17H99L83.7 40zM59 21.6a6 6 0 00-2.7 1.2 2.9 2.9 0 00-1 2.3 3.6 3.6 0 001.3 2.8 6.1 6.1 0 004.1 1.3c3 0 5.2-1 6.6-2.6a6.6 6.6 0 001.4-4V21a52.8 52.8 0 00-9.7.5zm-27.2-6.7a9 9 0 00-1.9 5.7 9 9 0 002 5.8 7.3 7.3 0 005.8 2.6 7.4 7.4 0 005.5-2.3 8.8 8.8 0 002.2-6.2 8.8 8.8 0 00-2-5.9 7.4 7.4 0 00-5.7-2.4 7.3 7.3 0 00-5.9 2.7zM7 14.3a5.9 5.9 0 00-1.8 4.2h14.5a6.2 6.2 0 00-2-4.6 7.4 7.4 0 00-5-1.8A8 8 0 007 14.3z"
      ></path>
    </svg>
  );
}

export default EbayLogo;
