import React from "react";

function ArrowIncreasing() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.7" height="12">
      <path
        fill="#0dbf4b"
        d="M11 6.9l.5-.6a.6.6 0 000-.9L6.3.2a.6.6 0 00-1 0l-5 5.2a.6.6 0 000 .9l.6.6a.6.6 0 00.9 0l3-3.2v7.7a.6.6 0 00.7.6h.9a.6.6 0 00.6-.6V3.7l3 3.2a.6.6 0 001 0z"
      ></path>
    </svg>
  );
}

export default ArrowIncreasing;
