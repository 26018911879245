import React from "react";

function ChartBar() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12.7">
      <path
        fill="#2d3748"
        d="M11 8.5h1.3a.5.5 0 00.4-.4V3.6a.5.5 0 00-.4-.4H11a.5.5 0 00-.4.4v4.5a.5.5 0 00.4.4zm3.2 0h1.3a.5.5 0 00.4-.4V.4a.5.5 0 00-.4-.4h-1.3a.5.5 0 00-.4.4v7.7a.5.5 0 00.4.4zm-9.5 0h1.2a.5.5 0 00.5-.4V5.7a.5.5 0 00-.5-.4H4.7a.5.5 0 00-.5.4v2.4a.5.5 0 00.5.4zm3.2 0H9a.5.5 0 00.5-.4V1.5A.5.5 0 009 1H8a.5.5 0 00-.5.4V8a.5.5 0 00.5.4zm8.6 2.1H2V.6a.5.5 0 00-.5-.6h-1a.5.5 0 00-.6.5v11.2a1 1 0 001 1h15.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.6z"
        opacity="0.2"
      ></path>
    </svg>
  );
}

export default ChartBar;
