import React from "react";
import Button from '../../Button';
import World from '../../icons/World';
import Login from '../../icons/Login';
import searchTrendsLogo from '../../../assets/images/searchtrendsLogo.png';

const Header = () => {
  return (
    <header className="flex justify-between w-full home-header">
          <div className="flex items-center">
          <img
              src={searchTrendsLogo}
              className="mr-12 home-footer-logo"
              alt="Logo da SearchTrends"
            />
            <a href="/" className="text-white home-header-anchor">
              Descubra tendências
            </a>
            <a href="/" className="text-white home-header-anchor">
              Pesquisas recentes
            </a>
            <a href="/" className="text-white home-header-anchor">
              Sobre
            </a>
          </div>
          <div className="flex items-center">
            <button className="flex items-center home-header-iconButton">
              <World /> Português
            </button>
            <button className="flex items-center home-header-iconButton">
              <Login /> Login
            </button>
            <Button className="home-header-button" text="Quero ser PRO" />
          </div>
        </header>
  );
};
export default Header;
