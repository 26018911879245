import React from "react";

function QuestionMark() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="16.4">
      <path
        fill="#2d3748"
        d="M5.6 0A6.2 6.2 0 00.1 3a.8.8 0 00.2 1l1.4 1a.8.8 0 001-.1c.8-1 1.4-1.6 2.7-1.6 1 0 2.2.6 2.2 1.6 0 .7-.6 1-1.6 1.6C5 7.1 3.4 8 3.4 10v.3a.8.8 0 00.8.8h2.3a.8.8 0 00.7-.8V10c0-1.3 4-1.4 4-5 0-2.9-2.9-5-5.6-5zm-.3 12a2.2 2.2 0 102.2 2 2.2 2.2 0 00-2.2-2z"
        opacity="0.2"
      ></path>
    </svg>
  );
}

export default QuestionMark;
