import React from "react";

function CiscoLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="95" height="49">
      <path
        fill="#2d3748"
        d="M77.7 49a8.9 8.9 0 01-6.2-2.4 8.4 8.4 0 010-11.9 9.2 9.2 0 0112.5 0 8.4 8.4 0 010 11.9 8.9 8.9 0 01-6.3 2.4zm0-12.6a4.3 4.3 0 00-3 1.2 4.3 4.3 0 000 6 4.4 4.4 0 006.1 0 4.3 4.3 0 000-6 4.3 4.3 0 00-3-1.2zM60.8 49a9.2 9.2 0 01-6.1-2.2 8 8 0 01-2.7-6.2 8.2 8.2 0 012.5-6 9 9 0 016.3-2.3 12.4 12.4 0 013.8.6v4.3a7.4 7.4 0 00-3.5-.9 4.7 4.7 0 00-3.3 1.3 4.4 4.4 0 000 6.1 4.6 4.6 0 003.3 1.3 7.5 7.5 0 003.5-.9v4.3a13.1 13.1 0 01-3.8.6zm-19.6 0a24 24 0 01-4.5-.4v-3.8a14.8 14.8 0 003.9.7c2 0 3-.6 3-1.6 0-.7-.6-1.2-1.8-1.6l-.4-.1h-.2L41 42a7.3 7.3 0 01-3-1.6 4.2 4.2 0 01-1.3-3 4.6 4.6 0 011.7-3.7 7 7 0 014.5-1.4 18.4 18.4 0 014 .5v3.4a14.2 14.2 0 00-3.2-.5c-1.6 0-2.6.6-2.6 1.4 0 1 1.2 1.3 1.8 1.5h.1l1.2.4c2.5.7 3.9 2.4 3.9 4.6a4.7 4.7 0 01-2.2 4.2 8.6 8.6 0 01-4.6 1.2zm-23.9 0a9.2 9.2 0 01-6.1-2.2 8 8 0 01-2.6-6.2 8.2 8.2 0 012.4-6 9 9 0 016.3-2.3 12.6 12.6 0 013.8.6v4.3a7.4 7.4 0 00-3.5-.9 4.7 4.7 0 00-3.3 1.3 4.4 4.4 0 000 6.1 4.6 4.6 0 003.3 1.3 7.5 7.5 0 003.5-.9v4.3a13.2 13.2 0 01-3.8.6zm75.8-.3h-.3v-2.1h.5l.6 1.7.6-1.7h.5v2.1h-.3V47l-.7 1.7h-.3l-.6-1.7v1.7zm-1.2 0h-.3v-1.9h-.8v-.2h1.8v.2H92v2zm-60.9 0h-4V32.6h4v16.1zm39.2-23.2a2 2 0 01-2-2V2a2 2 0 014 0v21.5a2 2 0 01-2 2zm-45.4 0a2 2 0 01-2-2V2a2 2 0 014 0v21.5a2 2 0 01-2 2zm68.1-4a2 2 0 01-2-2v-4.3a2 2 0 014.1 0v4.2a2 2 0 01-2 2zm-11.3 0a2 2 0 01-2-2v-10a2 2 0 014 0v9.8a2 2 0 01-2 2zm-22.7 0a2 2 0 01-2-2v-10a2 2 0 014 0v9.8a2 2 0 01-2 2zm-11.4 0a2 2 0 01-2-2v-4.3a2 2 0 014 0v4.2a2 2 0 01-2 2zm-11.3 0a2 2 0 01-2.1-2v-10a2 2 0 014.1 0v9.8a2 2 0 01-2 2zm-22.8 0a2 2 0 01-2-2v-10a2 2 0 014.1 0v9.8a2 2 0 01-2 2zm-11.3 0a2 2 0 01-2.1-2v-4.3a2 2 0 012-2 2 2 0 012.1 2v4.2a2 2 0 01-2 2z"
      ></path>
    </svg>
  );
}

export default CiscoLogo;
