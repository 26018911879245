import React from 'react';
import './index.css';
import KeywordDetailsCard from '../../components/KeywordDetailsCard';
import { StatusType } from '../../components/SmallKeywordCard';


const Result = () => {
  return (
    <section className="flex flex-col items-center home-tendencies-container">
      <h2 className="font-bold text-center mb-8 home-section-heading">
        Resultado da Busca
      </h2>
      <KeywordDetailsCard type={StatusType.INCREASING} />
    </section>
  );
};

export default Result;
