import React from 'react';
import './index.css';
import SmallGoogleLogo from '../icons/SmallGoogleLogo';

export const StatusType = {
  NEUTRAL: 'NEUTRAL',
  INCREASING: 'INCREASING',
  DECREASING: 'DECREASING',
};

/*
  type: StatusType - Tipo do card, vide acima
*/
const SmallKeywordCard = ({ type }) => {
  const getStatusClass = () => {
    const classes = {
      [StatusType.NEUTRAL]: 'smallKeywordCard-status--neutral',
      [StatusType.INCREASING]: 'smallKeywordCard-status--increasing',
      [StatusType.DECREASING]: 'smallKeywordCard-status--decreasing',
    };

    return classes[type];
  };

  return (
    <div className="flex bg-white smallKeywordCard-container">
      <div className="flex flex-col justify-between bg-white w-6/12 smallKeywordCard-content">
        <div className="flex items-center">
          Palavra-chave{' '}
          <span className={`smallKeywordCard-status ${getStatusClass()}`} />
        </div>
        <SmallGoogleLogo />
      </div>
      <div className="w-6/12 smallKeywordCard-chart">
      <svg xmlns="http://www.w3.org/2000/svg" width="184.213" height="83.223" viewBox="0 0 184.213 83.223" className="m-auto mt-5 mb-5">
        <g id="Group_10031" data-name="Group 10031" transform="translate(-956.848 -771.195)">
          <path id="Path_17417" data-name="Path 17417" d="M617.057,1816.532s-.577,27.148,14.841,27.148,14.827-78.223,37.913-78.223,25.966,71.891,41.67,71.891,14.666-51.356,27.957-51.356,14.03,30.541,30.329,30.541,26.541-51.075,26.541-51.075" transform="translate(342.301 -991.762)" fill="none" stroke="#1972f3" stroke-width="5"/>
        </g>
      </svg>

      </div>
    </div>
  );
};

export default SmallKeywordCard;
