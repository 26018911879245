import React from 'react';

const QuoteStart = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="36.4" height="29.6">
    <path
      fill="#116aeb"
      d="M10.6 0l4.8 4c-4.1 2.7-8 8-8.3 12.1l1.7-.1c3.6 0 6.2 2.7 6.2 6.6a6.9 6.9 0 01-6.9 7C3.8 29.6 0 26 0 19.6A25 25 0 0110.6 0zm21 0l4.8 4c-4.2 2.6-7.9 8-8.4 12l1.7-.1a6.3 6.3 0 016.4 6.6 7 7 0 01-7 7c-4.4 0-8.2-3.6-8.2-10 0-8 4.6-15.3 10.6-19.6z"
    ></path>
  </svg>
);

export default QuoteStart;
