import React from "react";

function Man() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.9" height="23.1">
      <path
        fill="#2d3748"
        d="M5.5 4.4a2.2 2.2 0 112.1-2.2 2.2 2.2 0 01-2.1 2.2zM3.8 23a1.3 1.3 0 01-1.3-1.4V8.3h-.4v5a1.2 1.2 0 01-.3.9 1 1 0 01-1.5 0 1.2 1.2 0 01-.3-.9V7.5a2.8 2.8 0 01.7-1.9 2.5 2.5 0 011.8-.8h6a2.4 2.4 0 011.8.8 2.8 2.8 0 01.7 1.9v5.8a1.1 1.1 0 01-.4.9 1 1 0 01-1.5 0 1.1 1.1 0 01-.3-.9v-5h-.4v13.3a1.3 1.3 0 11-2.7 0v-6.8h-.5v6.8a1.4 1.4 0 01-1.4 1.5z"
        opacity="0.2"
      ></path>
    </svg>
  );
}

export default Man;
