import React from "react";

const Tab = ({ label, active = false, onClick }) => {
  let classes = `tab rounded py-2 px-3 mr-2 cursor-pointer `;

  console.log(label, active);
  if (!active) classes += "hover:border-blue-100 border-b-4 border-white text-gray-800";
  if (active) classes+= "tab-active border-b-4 border-blue-500 text-gray-800";
  return (
    <div className={classes} onClick={onClick}>
      { label }
    </div>
  );
};

export default Tab;
