import React from "react";

function Pointer() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.5" height="16.7">
      <path
        fill="#2d3748"
        d="M9.9 10.8H6.4l1.8 4.4a.6.6 0 01-.3.8l-1.6.7a.6.6 0 01-.7-.3l-1.8-4.2L1 15a.6.6 0 01-1-.5V.6a.6.6 0 011-.4l9.3 9.5a.6.6 0 01-.4 1z"
        opacity="0.2"
      ></path>
    </svg>
  );
}

export default Pointer;
