import React from "react";

function Dashes() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="3">
      <path
        fill="none"
        stroke="rgba(45,55,72,.7)"
        strokeDasharray="3 4"
        strokeLinecap="round"
        strokeWidth="3"
        d="M1.5 1.5h10"
      ></path>
    </svg>
  );
}

export default Dashes;
