import React from 'react';
import './index.css';
import QuoteStart from '../icons/QuoteStart';
import QuoteEnd from '../icons/QuoteEnd';

/*
  logo: String - Url da logo da empresa citada,
  text: String - Texto do card
  userPhoto: String - Url da foto do usuário
  userName: String - Nome do usuário
  userJob: Empresa e emprego do usuário
*/
const LearnCard = ({ logo, text, userPhoto, userName, userJob }) => {
  return (
    <div className="learn-card-container">
      <div className="flex justify-between items-center">
        <QuoteStart />
        <img
          src={logo}
          alt="Descrição da imagem"
          className="object-contain learn-card-logo"
        />
      </div>
      <p className="text-base text-left learn-card-text">{text}</p>
      <div className="flex justify-between items-end">
        <div className="flex items-center">
          <img
            src={userPhoto}
            alt={`Foto de ${userName}`}
            className="mr-4 learn-card-userPhoto"
          />
          <div>
            <div className="text-sm font-bold learn-card-user">{userName}</div>
            <div className="text-sm learn-card-user">{userJob}</div>
          </div>
        </div>
        <QuoteEnd />
      </div>
    </div>
  );
};

export default LearnCard;
