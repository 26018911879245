import React from 'react';
import Header from '../components/elements/Header';
import Footer from '../components/sections/Footer';
import TrialCTA from '../components/sections/TrialCTA';
import Hero from '../components/sections/Hero';
import './index.css';

const MainLayout = ({ children }) => {
  return (
    <div>
      <section className="flex flex-col items-center relative home-heading">
        <Header />
        <Hero />
      </section>
      <section>
        { children }
      </section>
      <TrialCTA />
      <Footer />
    </div>
  );
};

export default MainLayout;
