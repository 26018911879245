import React from "react";

function World() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.3" height="13.3">
      <path
        fill="#fff"
        d="M6.6 0a6.6 6.6 0 106.7 6.6A6.6 6.6 0 006.5 0zM6 12.3a5.7 5.7 0 01-3.6-9.4 6.3 6.3 0 010 .8 2.5 2.5 0 00.4 1.9 3 3 0 01.3.9c0 .3.4.4.6.6L5 8c.3.2.5.3.4.7a2.5 2.5 0 01-.2.8 1.6 1.6 0 00.2.7l.8.7c.4.3 0 .8-.3 1.3zm4.7-1.7a5.6 5.6 0 01-3 1.6 2.4 2.4 0 01.8-1 2.4 2.4 0 00.6-.7 5.4 5.4 0 01.4-.7c.3-.4-.5-1-.8-1a8.3 8.3 0 01-1.5-1c-.3-.2-1 .2-1.5 0a7.8 7.8 0 01-1.5-1c-.5-.4-.4-.9-.4-1.4.4 0 1-.1 1.2.2 0 .1.3.5.5.4.2-.1 0-.7-.1-.8-.2-.4.3-.5.6-.8.3-.3 1-.8 1-1s-.7-1-1.1-.8l-.7.6v-.5L5 2.4c0-.2.5-.6.7-.7L5 1.4l-.8.3a1.5 1.5 0 000-.3A5.6 5.6 0 015.8 1l.4.2.3.4.3.3h.3l.5-.3-.1-.3V1a5.6 5.6 0 012.4 1h-.5a1.4 1.4 0 00-.2 0l.7 1.3c.2.4.7 1 .8 1.4v1.8a3 3 0 00.8 1.5 1.5 1.5 0 00.6 0 5.6 5.6 0 01-1.5 2.6z"
        opacity="0.7"
      ></path>
    </svg>
  );
}

export default World;
